import * as Sentry from '@sentry/react'
import type { BrandingType } from '@goschool/site'

export const sentryConfig = {
  dsn: 'https://47cc4a138410414102abe3be71aa2700@o1019182.ingest.us.sentry.io/4507879037206528',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration()
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
}

export const firebaseConfig = {
  apiKey: 'AIzaSyDUc5wugFKw-JZI9UrdVN-sl1Bsm3HrYY8',
  authDomain: 'goschool-obudaiegyetem.firebaseapp.com',
  projectId: 'goschool-obudaiegyetem',
  storageBucket: 'goschool-obudaiegyetem.appspot.com',
  messagingSenderId: '415972531714',
  appId: '1:415972531714:web:58f75c6ce0ffe38d98d11b',
  measurementId: 'G-G1NMSJ8N22'
}

export const brandingConfig: BrandingType = {
  primaryLogo: '/Obudai_Egyetem_LOGO_SARGA.svg',
  secondaryLogo: '/OE_NIK_inverz.png'
}
