import { createTheme, responsiveFontSizes } from '@mui/material'

import '@fontsource-variable/open-sans'
import '@fontsource-variable/rubik'
import type { LinkProps } from '@mui/material/Link'
import { LinkBehavior } from '@goschool/mui'

const designFont = 'Rubik, Arial, sans-serif'
const bodyFont = 'Open Sans, Arial, sans-serif'

export const goSchoolTheme = responsiveFontSizes(
  createTheme({
    palette: {
      mode: 'dark',
      background: {
        default: '#1d294d',
        paper: '#000'
      },
      primary: {
        main: '#9ad1f0'
      },
      secondary: {
        main: '#f9b000'
      }
    },
    typography: {
      fontFamily: bodyFont,
      body1: { fontFamily: bodyFont },
      body2: { fontFamily: bodyFont, fontSize: '0.8rem' },
      h1: { fontFamily: designFont },
      h2: { fontFamily: designFont },
      h3: { fontFamily: designFont },
      h4: { fontFamily: designFont },
      h5: { fontFamily: designFont },
      h6: { fontFamily: designFont },
      subtitle1: { fontFamily: designFont },
      subtitle2: { fontFamily: designFont }
    },

    components: {
      MuiLink: {
        defaultProps: {
          component: LinkBehavior
        } as LinkProps
      },
      MuiButtonBase: {
        defaultProps: {
          LinkComponent: LinkBehavior
        }
      }
    }
  })
)
